<!--预约到店验车-->
<template>
  <div class="bookInspection">
    <van-form ref="form" validate-first labelWidth="100px" :show-error-message="false">
      <div class="base-info">
        <BasicComponent :details="details" />
      </div>
      <div class="common-t">
        <div class="common-t-l">{{ $t("预约验车时间") }}</div>
        <div v-if="details.bookVisitTime" class="common-t-r" @click="modifyTime">
          {{ $t("修改时间") }}
        </div>
      </div>
      <van-field v-model="params.bookTime" :label="$t('预约验车时间')" required :placeholder="$t('选择时间')"
        :rules="[{ required: true }]" :right-icon="details.bookVisitTime ? '' : 'arrow'" readonly input-align="right"
        @click="showPickerFun">
      </van-field>
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker v-if="showPicker" ref="datetimePicker"
          :style="{ height: details.bookVisitTime ? '400px' : '' }" type="datetime" :title="$t('选择时间')"
          :min-date="minDate" @cancel="showPicker = false" @confirm="dateConfirm">
          <template v-if="details.bookVisitTime" #default>
            <div class="slotTitle">{{ $t("修改时间") }}</div>
          </template>
        </van-datetime-picker>
        <div v-if="details.bookVisitTime" class="modifyBtn">
          <van-button class="btn" native-type="button" @click.stop="showPicker = false">{{ $t("取消") }}</van-button>
          <van-button class="btn submit" :loading="btnLoading" native-type="button" @click.stop="modifyConfirm">{{
      $t("确定")
    }}</van-button>
        </div>
      </van-popup>
      <div class="common-footer">
        <van-button class="btn back" native-type="button" @click.stop="goBack">{{ $t("返回") }}</van-button>
        <van-button v-if="details.bookVisitTime" class="btn" native-type="button" @click.stop="onSubmit(2)">{{
      $t("取消看车")
    }}</van-button>
        <van-button v-else class="btn submit" type="submit" @click="onSubmit(1)">{{ $t("提交") }}</van-button>
        <van-button v-if="details.bookVisitTime" class="btn submit btn-l" type="submit" @click="onSubmit(4)">{{
      $t("客户已到店")
          }}</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import BasicComponent from './component/basic-component.vue'
import deliveryServices from '@/services/deliveryServices.js'
import dayjs from 'dayjs'
import loading from '@/utils/loading'
export default {
  components: { BasicComponent },
  data() {
    return {
      details: {},
      params: {
        bookTime: '',
      },
      btnLoading: false,
      showPicker: false,
      minDate: new Date(),
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    // 获取详情
    async getDetail() {
      loading.showLoading()
      const res = await deliveryServices.deliveryDetail({
        id: this.$route.query.id,
      })
      loading.hideLoading()
      this.details = res || {}
      if (res.bookVisitTime)
        this.params.bookTime = dayjs(res.bookVisitTime).format(
          'YYYY-MM-DD HH:mm'
        )
    },

    showPickerFun() {
      if (this.details.bookVisitTime) return false
      this.showPicker = true
    },
    // 修改时间
    modifyTime() {
      this.showPicker = true
    },
    // 修改时间的确认
    modifyConfirm() {
      this.$refs.datetimePicker.getPicker().confirm()
    },
    // 确认时间
    dateConfirm(date) {
      if (this.details.bookVisitTime) {
        this.showPicker = false
        this.onSubmit(3, date)
        return false
      }
      this.params.bookTime = dayjs(date).format('YYYY-MM-DD HH:mm')
      this.showPicker = false
    },
    // 提交
    onSubmit(type, date) {
      let params = {
        orderId: this.$route.query.id,
        type,
        bookTime: date
          ? dayjs(date).format('YYYY-MM-DD HH:mm:00')
          : dayjs(this.params.bookTime).format('YYYY-MM-DD HH:mm:00'),
      }
      if (type == 2) {
        delete params.bookTime
      }
      if (this.btnLoading) return false
      this.$refs.form.validate().then(async () => {
        this.btnLoading = true
        loading.showLoading()
        try {
          const res = await deliveryServices.deliverRemindBook(params)
          this.$toast.success(this.$t('操作成功'))
          setTimeout(() => {
            this.btnLoading = false
            this.$router.go(-1)
            loading.hideLoading()
          }, 1500)
        } catch {
          this.btnLoading = false
          loading.hideLoading()
        }
      })
    },
    // 返回
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="less" scoped>
.bookInspection {
  padding: 16px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);

  .base-info {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;

    /deep/ .basic-component {
      .options {
        border-bottom: none;
        margin-bottom: 0px;
      }
    }
  }

  .common-t {
    border-bottom: none;

    .common-t-r {
      padding-right: 16px;
    }
  }

  /deep/ .modifyBtn {
    max-width: 500px;
    width: 100%;
    height: 96px;
    display: flex;
    position: fixed;
    z-index: 9;
    bottom: 0px;
    align-items: center;
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 16px;
    justify-content: space-between;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 16px;
    color: @black;
    left: 50%;
    transform: translate(-50%, 0%);

    .btn {
      flex: 1;
      border: 1px solid @black;
      border-radius: 100px;
      cursor: pointer;

      &:not(:last-of-type) {
        margin-right: 8px;
      }

      &.submit {
        background: @yellow;
        border: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  /deep/ .slotTitle {
    padding: 16px;
    font-size: 18px;
    font-weight: 500;
  }

  /deep/ .btn-l {
    width: fit-content !important;
  }
}
</style>
